import React from "react";

export default ({ width, stroke }) => {
    return (
        <svg viewBox={`0 0 ${width} ${width}`} width={`${width}px`}>
            <path
                d={`M${width / 4},${stroke} L${stroke},${stroke} L${stroke},${
                    width / 4
                }`}
                fill="none"
                stroke="#FFE05C"
                strokeWidth={stroke}
            />
            <path
                d={`M${stroke},${(width / 4) * 3} L${stroke},${
                    width - stroke
                } L${width / 4},${width - stroke}`}
                fill="none"
                stroke="#FFE05C"
                strokeWidth={stroke}
            />
            <path
                d={`M${(width / 4) * 3},${width - stroke} L${width - stroke},${
                    width - stroke
                } L${width - stroke},${(width / 4) * 3}`}
                fill="none"
                stroke="#FFE05C"
                strokeWidth={stroke}
            />
            <path
                d={`M${width - stroke},${width / 4} L${
                    width - stroke
                },${stroke} L${(width / 4) * 3},${stroke}`}
                fill="none"
                stroke="#FFE05C"
                strokeWidth={stroke}
            />
        </svg>
    );
};
