import { useTranslation } from "react-i18next";
import Avatar from "antd/es/avatar";

const DetailResultItemOnImage = ({ faceAge, eyesAge, noseAge, lipsAge }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="detail-result-item-title">{t("age")}</div>
            <div className="detail-result-face-title">{t("face")}</div>
            <div className="detail-result-face-age">{faceAge.toFixed(0)}</div>
            <div className="detail-result-eyes-title">{t("eyes")}</div>
            <div className="detail-result-eyes-age">{eyesAge.toFixed(0)}</div>
            <div className="detail-result-nose-title">{t("nose")}</div>
            <div className="detail-result-nose-age">{noseAge.toFixed(0)}</div>
            <div className="detail-result-lips-title">{t("lips")}</div>
            <div className="detail-result-lips-age">{lipsAge.toFixed(0)}</div>
        </>
    );
};

export default DetailResultItemOnImage;
