import React, { useLayoutEffect, useState } from "react";

const getIsMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

export default () => {
    const [size, setSize] = useState(0);
    const [isMobile, setIsMobile] = useState(getIsMobile());
    useLayoutEffect(() => {
        function updateSize() {
            // document.body.scrollWidth returns width of the body elements, excluding width of the scroll bar
            setSize(document.body.scrollWidth);
            setIsMobile(getIsMobile());
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return [size, isMobile];
};
