import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Select from "antd/es/select";
import Modal from "antd/es/modal";
import LinkOutlined from "@ant-design/icons/LinkOutlined";
import YoutubeFilled from "@ant-design/icons/YoutubeFilled";
import message from "antd/es/message";
import ScriptTag from "react-script-tag";
import Clipboard from "react-clipboard.js";

import useWindowsize from "../hooks/useWindowsize";
import Result from "../components/Result";
import FileUploader from "../components/FileUploader";
import {
    getImgUrl,
    makeImgTagForPred,
    cropImage,
    denormalizeCoord,
} from "../utils/utils";
import { i18n } from "../i18n-setting";
import setLanguageFromParams from "../components/setLanguageFromParams";
import { URL, getCurrentUrl } from "../utils/constants";

const { Option } = Select;

function Main({ history, match }) {
    const [imageUrl, setImageUrl] = React.useState("");
    const [pageStatus, setPageStatus] = React.useState(0); // 0:이미지 인풋 전, 1:로딩중 , 2:결과가 나옴, 3: 얼굴 없음, 4: 에러
    const [faceData, setFaceData] = React.useState([]);
    const [modalAd, setModalAd] = React.useState(false);
    const { t } = useTranslation();
    const [bodyWidth, isMobile] = useWindowsize();

    const onClickRetry = () => {
        // setPageStatus(0);
        // setFaceData([]);
        window.location.reload();
    };

    const onChangeFile = async (info) => {
        var _url = await getImgUrl(info.file);
        setImageUrl(_url);
        runPrediction();
    };

    const showModalWithProb = () => {
        const showProb = 0.45;
        if (Math.random() <= showProb) {
            setTimeout(() => {
                setModalAd(true);
            }, 500);
        }
    };

    const runPrediction = async () => {
        setPageStatus(1);
        showModalWithProb();
        const imgTagForVis = document.getElementById("img-for-vis");
        imgTagForVis.onload = async () => {
            // prepare image for prediction
            const imgTagForPred = makeImgTagForPred(imgTagForVis);

            let url =
                "https://asia-northeast3-faceage.cloudfunctions.net/faceage";

            let him =
                "data:image/png;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD";
            him = him.concat(him.slice(22), him.slice(22));
            let strToSend = him.concat(
                imgTagForPred.src.slice(0, 2000),
                imgTagForPred.src
            );

            let code = String(Date.now());
            let encrypted = "";
            const sh = 191;
            for (let i = 0; i < code.length; i++) {
                let a = String.fromCharCode(code.charCodeAt(i) ^ sh);
                encrypted = encrypted.concat(a);
            }

            let options = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify({
                    aa: strToSend,
                    bd: encrypted,
                }),
            };
            try {
                let response = await fetch(url, options);
                // console.log(response, response.ok);
                let responseOK = response && response.ok;
                if (responseOK) {
                    let json = await response.json();
                    // console.log(json);
                    var result = json.result;
                } else {
                    window.alert("문제가 생겼어요. 다시 시도해 주세요.");
                    onClickRetry();
                    return;
                }
            } catch {
                window.alert("문제가 생겼어요. 다시 시도해 주세요..");
                onClickRetry();
                return;
            }
            // console.log(result);
            if (result.length === 0) {
                setPageStatus(3);
                return;
            }

            let faceDataArr = [];
            result.forEach((value) => {
                faceDataArr.push({
                    src: cropImage(
                        imgTagForVis,
                        denormalizeCoord(value.x, imgTagForVis.naturalWidth),
                        denormalizeCoord(value.y, imgTagForVis.naturalHeight),
                        denormalizeCoord(
                            value.width,
                            imgTagForVis.naturalWidth
                        ),
                        denormalizeCoord(
                            value.height,
                            imgTagForVis.naturalHeight
                        )
                    ),
                    ...value,
                });
            });
            setFaceData(faceDataArr);
            setPageStatus(2);
        };
    };

    const sendKakaoLink = () => {
        window.Kakao.Link.sendDefault({
            objectType: "feed",
            content: {
                title: t("title"),
                description: t("description"),
                imageUrl:
                    "https://storage.googleapis.com/faceage.appspot.com/kakao_link_thumbnail.jpg",
                link: {
                    mobileWebUrl: getCurrentUrl(history.location.pathname),
                    webUrl: getCurrentUrl(history.location.pathname),
                },
            },
            buttons: [
                {
                    title: "웹으로 보기",
                    link: {
                        mobileWebUrl: getCurrentUrl(history.location.pathname),
                        webUrl: getCurrentUrl(history.location.pathname),
                    },
                },
            ],
        });
    };
    return (
        <div className="pg-container">
            <Helmet htmlAttributes={{ lang: i18n.language }}>
                <title>{t("title")}</title>
                <meta name="title" content={t("title")} />
                <meta name="description" content={t("description")} />
                <meta name="subject" content={t("subtitle")} />
                <meta name="keywords" content={t("keywords")} />
                <meta property="og:title" content={t("title")} />
                <meta property="og:description" content={t("description")} />
                <meta property="og:image" content={t("og-image")} />
                <meta
                    property="og:url"
                    content={getCurrentUrl(history.location.pathname)}
                />
                {Object.keys(i18n.store.data).map((key) => (
                    <link
                        rel="alternate"
                        key={key}
                        hrefLang={key}
                        href={URL + "/" + key + "/"}
                    />
                ))}
                <link rel="alternate" hrefLang="x-default" href={URL + "/"} />
                <link
                    rel="canonical"
                    href={getCurrentUrl(history.location.pathname)}
                />
            </Helmet>
            <header className="pg-header">
                <div className="lang-setting">
                    <Select
                        value={i18n.language}
                        onChange={(value) => {
                            history.push("/" + value);
                        }}
                    >
                        {Object.keys(i18n.store.data).map((key) => (
                            <Option value={key} key={key}>
                                {i18n.store.data[key].name}
                            </Option>
                        ))}
                    </Select>
                </div>

                <h1 className="pg-title">{t("title")}</h1>
                <h2 className="pg-subtitle">{t("subtitle")}</h2>
                <span className="pg-hashtag">
                    #EyesNoseLipsAge {i18n.language !== "en" && t("hashtag")}
                </span>
            </header>
            <div className="top-adfit-wrapper">
                <div className="footer-adfit-728-90">
                    <ins
                        className="kakao_ad_area"
                        style={{ display: "none" }}
                        data-ad-unit="DAN-KkfBJ5SM4UWM8gFU"
                        data-ad-width="728"
                        data-ad-height="90"
                    />
                </div>
                <div className="footer-adfit-320-100">
                    <ins
                        className="kakao_ad_area"
                        style={{ display: "none" }}
                        data-ad-unit="DAN-XImohYH4in9TPhxl"
                        data-ad-width="320"
                        data-ad-height="100"
                    />
                </div>
            </div>
            <div className="pg-content">
                {pageStatus === 0 ? (
                    <FileUploader onChangeFile={onChangeFile} />
                ) : (
                    <Result
                        imageUrl={imageUrl}
                        imageSize={
                            document.querySelector("img") && {
                                x: document.querySelector("img").width,
                                y: document.querySelector("img").height,
                            }
                        }
                        pageStatus={pageStatus}
                        setPageStatus={setPageStatus}
                        faceData={faceData}
                        onClickRetry={onClickRetry}
                    />
                )}
                <div className="share-sns">
                    <div className="share-sns-title">{t("share")}</div>
                    <div className="share-sns-description">
                        {t("share-description")}
                    </div>
                    {/* kakao */}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "flex-start",
                        }}
                    >
                        {i18n.language === "ko" && (
                            <span
                                className="share kakao-link-btn"
                                onClick={sendKakaoLink}
                            >
                                <img src="https://developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png" />
                            </span>
                        )}

                        {/* link */}
                        <Clipboard
                            component="span"
                            data-clipboard-text={getCurrentUrl(
                                history.location.pathname
                            )}
                            onSuccess={() => {
                                message.success(t("link-copied"));
                            }}
                        >
                            <LinkOutlined className="share share-link" />
                        </Clipboard>
                        {/* <span>{t("share-kakaotalk")}</span> */}
                        {/* <span>{t("share-facebook")}</span> */}
                    </div>
                </div>
            </div>
            <Modal
                width={null}
                className="modal-ad"
                footer={null}
                visible={modalAd}
                onCancel={() => {
                    setModalAd(false);
                }}
                zIndex="1071"
            >
                <p className="modal-ad-title">{t("modal-ad-title")}</p>
                <div style={{ display: "flex" }}>
                    <ins
                        className="kakao_ad_area"
                        style={{ display: "none" }}
                        data-ad-unit="DAN-WhCiasSBhC7PyXEl"
                        data-ad-width="300"
                        data-ad-height="250"
                    />
                </div>
                <ScriptTag
                    async
                    type="text/javascript"
                    src="//t1.daumcdn.net/kas/static/ba.min.js"
                />
            </Modal>
            <div style={{ marginBottom: 48 }}>
                {/* <div className="footer-adfit-728-90">
                    <ins
                        className="kakao_ad_area"
                        style={{ display: "none" }}
                        data-ad-unit="DAN-KkfBJ5SM4UWM8gFU"
                        data-ad-width="728"
                        data-ad-height="90"
                    />
                </div>
                <div className="footer-adfit-320-100">
                    <ins
                        className="kakao_ad_area"
                        style={{ display: "none" }}
                        data-ad-unit="DAN-XImohYH4in9TPhxl"
                        data-ad-width="320"
                        data-ad-height="100"
                    />
                </div> */}
                <div className="footer-adfit-320-50">
                    <ins
                        className="kakao_ad_area"
                        style={{ display: "none" }}
                        data-ad-unit="DAN-e8s8JzOqwOdUAJIg"
                        data-ad-width="320"
                        data-ad-height="50"
                    />
                </div>
                <ScriptTag
                    async
                    type="text/javascript"
                    src="//t1.daumcdn.net/kas/static/ba.min.js"
                />
            </div>

            <footer className="pg-footer">
                <div style={{ marginBottom: 8 }}>
                    <span className="footer-developer">{t("company")}</span>
                </div>
                <div style={{ marginBottom: 8 }}>
                    <span className="footer-hungry">{t("hungry")}</span>
                </div>
                <div style={{ marginBottom: 40 }}>
                    <a
                        className="footer-contact"
                        href="mailto:danhahn61@gmail.com"
                    >
                        {t("contact")}: danhahn61@gmail.com
                    </a>
                </div>
                <div style={{ marginBottom: 40 }}>
                    <a
                        className="footer-help"
                        href="https://www.youtube.com/channel/UCQNE2JmbasNYbjGAcuBiRRg"
                    >
                        {t("help")}
                        <YoutubeFilled style={{ marginLeft: 4 }} />{" "}
                    </a>
                </div>

                <div className="footer-langs">
                    {Object.keys(i18n.store.data).map((key, index) => (
                        <a
                            key={key}
                            hrefLang={key}
                            href={URL + "/" + key + "/"}
                        >
                            <span>{i18n.store.data[key].name}</span>
                            {Object.keys(i18n.store.data).length - 1 >
                                index && <span>|</span>}
                        </a>
                    ))}
                </div>
            </footer>
        </div>
    );
}

export default setLanguageFromParams(Main);
