const URL = "https://eyes-nose-lips.com";

const getCurrentUrl = (pathname) => {
    let url = URL.concat(pathname);
    if (!url.endsWith("/")) {
        url = url.concat("/");
    }
    return url;
};

export { URL, getCurrentUrl };
