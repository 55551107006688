import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Lang from "./translation/languages.json";

const DEFAULT_LANGUAGE = "ko";

const resources = Lang;

const setDefaultLanguage = () => {
    i18n.changeLanguage(DEFAULT_LANGUAGE);
};

const getAvailableLang = (lang) => {
    if (lang in resources) {
        return lang;
    } else {
        return DEFAULT_LANGUAGE;
    }
};

const initI18n = () => {
    i18n.use(initReactI18next).init({
        resources,
        lng: DEFAULT_LANGUAGE,
        fallbackLng: DEFAULT_LANGUAGE,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });
};

export { initI18n, setDefaultLanguage, i18n };
