import Upload from "antd/es/upload";
import InboxOutlined from "@ant-design/icons/InboxOutlined";
import { useTranslation } from "react-i18next";

const { Dragger } = Upload;

const FileUploader = ({ onChangeFile }) => {
    const { t } = useTranslation();
    const draggerProps = {
        name: "file",
        accept: "image/*",
        beforeUpload: () => false,
        showUploadList: false,
        onChange: onChangeFile,
    };

    return (
        <div className="file-upload">
            <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t("click-or-drag")}</p>
                <p className="ant-upload-hint">
                    <span style={{ color: "red" }}>* </span>
                    {t("click-or-drag-detail")}
                </p>
                <p className="ant-upload-hint">
                    <span style={{ color: "red" }}>* </span>
                    {t("click-or-drag-celeb")}
                </p>
            </Dragger>
        </div>
    );
}; // pageStatus === 0

export default FileUploader;
