import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "antd/es/button";
import Spin from "antd/es/spin";
import Tooltip from "antd/es/tooltip";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

import DetailResult from "./DetailResult";
import DetailResultItemOnImage from "./DetailResultItemOnImage";
import { denormalizeCoord } from "../utils/utils";
import { URL } from "../utils/constants";
import SquareSel from "../svg/square_sel";

const loadingIcon = <LoadingOutlined spin />;

const Result = ({
    faceData,
    pageStatus,
    onClickRetry,
    imageUrl,
    imageSize,
    history,
}) => {
    const { t } = useTranslation();
    const [active, setActive] = React.useState([0]);
    const [activeAll, setActiveAll] = React.useState(false);

    const onClickFace = (index) => (v) => {
        if (!activeAll) {
            setActive([index]);
            if (v) {
            }
        }
    };

    const getPlacement = (left, index) => {
        if (faceData.length == 1) {
            if (left > 100) {
                return "left";
            }
        } else if (activeAll) {
            return index % 2 == 0 ? "top" : "bottom";
        } else {
            return "top";
        }
    };

    const getSelStroke = (width) => {
        if (width > 200) {
            return 10;
        } else if (width > 150) {
            return 8;
        } else if (width > 100) {
            return 6;
        } else if (width > 50) {
            return 4;
        } else if (width > 25) {
            return 2;
        } else {
            return 1;
        }
    };

    const getNonSelStroke = (width) => {
        if (width > 200) {
            return 5;
        } else if (width > 150) {
            return 4;
        } else if (width > 100) {
            return 3;
        } else if (width > 50) {
            return 2;
        } else {
            return 1;
        }
    };

    const getMaxWidth = () => {
        let x = imageSize && imageSize.x;
        let y = imageSize && imageSize.y;
        if (x / y >= 1.2) {
            // 가로가 더 긴 경우
            return 768;
        } else {
            return 500;
        }
    };

    const mapFaceDataToTooltip = (item, index) => {
        let topDenorm = denormalizeCoord(item.y, imageSize.y);
        let leftDenorm = denormalizeCoord(item.x, imageSize.x);
        let widthDenorm = denormalizeCoord(item.width, imageSize.x);
        let heightDenorm = denormalizeCoord(item.height, imageSize.y);
        return (
            <Tooltip
                key={index}
                title={<DetailResultItemOnImage {...item} />}
                visible={active.includes(index)}
                trigger="click"
                onVisibleChange={onClickFace(index)}
                mouseEnterDelay={0}
                mouseLeaveDelay={0.05}
                placement={getPlacement(leftDenorm, index)}
                color={"rgba(0, 0, 0, 0.5)"}
            >
                <div
                    className={`face-box ${active.includes(index) && "active"}`}
                    style={{
                        top: topDenorm,
                        left: leftDenorm,
                        width: widthDenorm,
                        height: heightDenorm,
                        borderWidth: getNonSelStroke(widthDenorm),
                    }}
                >
                    {active.includes(index) && (
                        <SquareSel
                            width={denormalizeCoord(item.width, imageSize.x)}
                            stroke={getSelStroke(widthDenorm)}
                        />
                    )}
                </div>
            </Tooltip>
        );
    };
    return (
        <div className="result" style={{ maxWidth: getMaxWidth() }}>
            <div className="image">
                <Spin
                    spinning={pageStatus === 1}
                    indicator={loadingIcon}
                    tip={t("loading-message")}
                    size="large"
                >
                    <img id="img-for-vis" src={imageUrl} alt={"input file"} />
                    {pageStatus === 2 && (
                        <div className="url-label">
                            {URL + history.location.pathname}
                        </div>
                    )}
                    {faceData.map(mapFaceDataToTooltip)}
                </Spin>
            </div>
            {pageStatus === 3 && (
                <div className="fail-text">{t("no-face-data")}</div>
            )}

            {pageStatus !== 1 && (
                <Button className="result-button" onClick={onClickRetry}>
                    {t("retry")}
                </Button>
            )}
            {pageStatus === 2 && faceData.length > 1 && (
                <>
                    <DetailResult
                        faceData={faceData}
                        onClickFace={onClickFace}
                        active={active}
                        activeAll={activeAll}
                        setActive={setActive}
                        setActiveAll={setActiveAll}
                    />
                </>
            )}
        </div>
    );
}; // pageStatus === 1 || pageStatus === 2 || pageStatus === 3

export default withRouter(Result);
