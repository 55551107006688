import React from "react";
import { Route } from "react-router-dom";

import "./App.less";
import { initI18n } from "./i18n-setting";
import Main from "./pages/Main";
function App() {
    const [isInit, setIsInit] = React.useState(false);
    React.useEffect(() => {
        initI18n();
        setIsInit(true);
    }, []);

    return isInit && <Route path="/:lang?" component={Main} exact></Route>;
}

export default App;
