import { useTranslation } from "react-i18next";
import Switch from "antd/es/switch";

import DetailResultItem from "./DetailResultItem";

const DetailResult = ({
    faceData,
    onClickFace,
    active,
    activeAll,
    setActive,
    setActiveAll,
}) => {
    const { t } = useTranslation();
    return (
        <div className="detail-result-wrapper">
            <div className="detail-result-title">{t("detail-result")}</div>
            <div className="toggle">
                <div className="toggle-title">전체보기</div>
                <Switch
                    checked={activeAll}
                    onChange={() => {
                        if (activeAll) {
                            setActiveAll(false);
                            setActive([]);
                        } else {
                            setActiveAll(true);
                            setActive(
                                Array.from(
                                    { length: faceData.length },
                                    (v, k) => k
                                )
                            );
                        }
                    }}
                />
            </div>
            <div className="detail-result-list">
                {faceData.map((item, index) => (
                    <DetailResultItem
                        onClickFace={onClickFace}
                        active={active}
                        faceBox
                        index={index}
                        key={index}
                        {...item}
                    />
                ))}
            </div>
        </div>
    );
};
export default DetailResult;
