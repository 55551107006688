import { useTranslation } from "react-i18next";
import Avatar from "antd/es/avatar";

const DetailResultItem = ({
    src,
    faceAge,
    eyesAge,
    noseAge,
    lipsAge,
    faceBox,
    onClickFace,
    active,
    index,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <div
                onClick={() => {
                    onClickFace(index)();
                }}
                className={
                    active.includes(index)
                        ? "detail-result-item active"
                        : "detail-result-item"
                }
            >
                {faceBox && (
                    <div className="detail-result-image">
                        <Avatar shape="square" size={64} src={src} />
                    </div>
                )}
                <div className="detail-result-info">
                    <div>{t("face")}</div>
                    <div>
                        {faceBox ? faceAge.toFixed(1) : faceAge.toFixed(0)}
                    </div>
                </div>
                <div className="detail-result-info">
                    <div>{t("eyes")}</div>
                    <div>
                        {faceBox ? eyesAge.toFixed(1) : eyesAge.toFixed(0)}
                    </div>
                </div>
                <div className="detail-result-info">
                    <div>{t("nose")}</div>
                    <div>
                        {faceBox ? noseAge.toFixed(1) : noseAge.toFixed(0)}
                    </div>
                </div>
                <div className="detail-result-info">
                    <div>{t("lips")}</div>
                    <div>
                        {faceBox ? lipsAge.toFixed(1) : lipsAge.toFixed(0)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailResultItem;
