import React from "react";
import { i18n, setDefaultLanguage } from "../i18n-setting";

export default (WrappedComponent) => (props) => {
    const { history, match } = props;
    React.useEffect(() => {
        // console.log(history);
        // console.log(match);
        // console.log(match.params.lang);
        // console.log(i18n.language);
        if (!match.params.lang) {
            // console.log("/"); // i18n-setting 에서 언어 초기화(뒤로가기가 있네!) -> 기본언어로 설정
            setDefaultLanguage();
        } else if (!(match.params.lang in i18n.store.data)) {
            // console.log("params lang not matched");
            history.push("/"); // url 파라미터가 변경되었으나 해당 언어가 지원되지 않음. -> 루트 '/' 로 이동
        } else {
            // console.log("change language to " + match.params.lang);
            // onChangeLanguage(match.params.lang); // url 파라미터가 변경되고 해당 언어가 지원됨. -> 해당 언어로 언어 설정
            i18n.changeLanguage(match.params.lang);
            // console.log(i18n.language);
        }
    }, [match.params.lang]);
    return <WrappedComponent {...props} />;
};
