import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import App from "./App";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

const RouterApp = () => {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
};

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<RouterApp />, rootElement);
} else {
    render(
        <React.StrictMode>
            <RouterApp />
        </React.StrictMode>,
        rootElement
    );
}
