import i18n from "i18next";

const getImgUrl = (img) =>
    new Promise((res, rej) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = () => res(reader.result);
    });

const onChangeLanguage = (value) => {
    i18n.changeLanguage(value);
}; // 언어 설정 변경

const cropImage = (img, x, y, width, height) => {
    var workCan = document.createElement("canvas");
    workCan.width = Math.floor(width);
    workCan.height = Math.floor(height);
    var ctx = workCan.getContext("2d");
    ctx.drawImage(img, -Math.floor(x), -Math.floor(y));
    return workCan.toDataURL("image/jpeg");
};

const resizeImg = (img, newWidth, newHeight) => {
    var workCan = document.createElement("canvas");
    workCan.width = Math.floor(newWidth);
    workCan.height = Math.floor(newHeight);
    var ctx = workCan.getContext("2d");
    ctx.drawImage(img, 0, 0, newWidth, newHeight);
    return workCan.toDataURL("image/jpeg");
};

const makeImgTagForPred = (imgTagForVis) => {
    let w = imgTagForVis.naturalWidth;
    let h = imgTagForVis.naturalHeight;
    let nw = w;
    let nh = h;
    const pixels = w * h;
    const minPixels = 1024 * 1024;
    if (pixels > minPixels) {
        let r = Math.sqrt(pixels / minPixels);
        nw = Math.floor(w / r);
        nh = Math.floor(h / r);
        // console.log(`resize img: ${w}x${h} -> ${nw}x${nh}`);
    }
    let imgTagForPred = document.createElement("img");
    imgTagForPred.src = resizeImg(imgTagForVis, nw, nh);
    return imgTagForPred;
};

const makeRandomData = (hwratio) => {
    let data = [];
    let numFaces = Math.random() * 4;
    for (let i = 0; i < numFaces; i++) {
        var w, h;
        if (hwratio >= 1) {
            w = Math.random();
            h = w / hwratio;
        } else {
            h = Math.random();
            w = h * hwratio;
        }

        data.push({
            x: Math.random() * (1 - w),
            y: Math.random() * (1 - h),
            width: w,
            height: h,
            eyesAge: Math.random() * 67 + 3,
            noseAge: Math.random() * 67 + 3,
            lipsAge: Math.random() * 67 + 3,
            faceAge: Math.random() * 67 + 3,
        });
    }
    return data;
};

const denormalizeCoord = (coord, size) => {
    return coord * (size - 1);
};

export {
    getImgUrl,
    onChangeLanguage,
    cropImage,
    resizeImg,
    makeImgTagForPred,
    makeRandomData,
    denormalizeCoord,
};
